<template>
    <v-sheet>
        <div height="100px" id="camera-test"></div>
    </v-sheet>
</template>

<script>
import {
	createLocalVideoTrack,
} from "twilio-video";


// Display the video preview.
export default {
    name: "TestCamera", 
    data: () => {
        return {
            track: null
        }
    },
    methods: {
        async test(){
            const videoTrack = await createLocalVideoTrack();
            this.track = videoTrack;
            const divContainer = document.getElementById('camera-test');
            const videoElement = videoTrack.attach();
            divContainer.appendChild(videoElement);
        }
    }, 
    created(){
        this.test();
    },
    destroyed(){
        this.track.stop();
    }
    
}
</script>