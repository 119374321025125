<template>
    

    <v-sheet>
        <v-icon>mdi-microphone</v-icon>
        <v-icon v-for="n in 10" color="primary" :key="n">
            {{ level > n ? 'mdi-circle' : 'mdi-circle-outline'}}
        </v-icon>
    </v-sheet>
</template>


<script>


import {
	createLocalAudioTrack,
} from "twilio-video";

import videoUtilities  from "@/assets/videoUtilities.js";


// const { createLocalAudioTrack } = require('twilio-video');
// const pollAudioLevel = require('./pollaudiolevel');


export default {
    name: "TestMic", 
    data: () => {
        return {
             level: 0, 
             track: null
        }
    },
    methods: {
        async test(){
            const self = this;
            const audioTrack = await createLocalAudioTrack();
            self.track = audioTrack;
            videoUtilities.pollAudioLevel(audioTrack, level => {
                self.level = level;
            });
        }

    },
    created(){
        this.test();
    },
    destroyed(){
        this.track.stop();
    }
}
</script>