<template>
	<content-page>
		<template v-slot:content>
			<render-content id="techtest"></render-content>
            <v-sheet class="mb-10">
                <test-mic class="mb-4"></test-mic>
                <test-camera></test-camera>
                <p class="text-right">
                    <v-btn router-link to="/" color="primary"> Done </v-btn>
                </p>
            </v-sheet>
		</template>
	</content-page>
</template>

<script>
import TestMic from "@c/tech-test/TestMic"
import RenderContent from "@c/ui/RenderContent";
import ContentPage from "@c/ui/ContentPage";
import TestCamera from '@c/tech-test/TestCamera.vue';
export default {
	name: "TechTest",
	components: {
		RenderContent,
		ContentPage,
        TestMic,
        TestCamera
    },
};
</script>
